import format from 'date-fns/format'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { injectIntl, Link } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: flex-start;
  padding: 1rem;
  overflow-x: hidden;
`

const Album = styled.div`
  padding: 1rem;
`

const Image = styled(GatsbyImage)`
  width: 400px;
  height: 400px;
  @media all and (max-width: 320px) {
    width: 300px;
    height: 300px;
  }
  @media all and (max-width: 400px) {
    width: 350px;
    height: 350px;
  }
`

const Title = styled.div`
  max-width: 400px;
  @media all and (max-width: 320px) {
    max-width: 300px;
  }
  @media all and (max-width: 400px) {
    max-width: 350px;
  }
  height: fit-content;
  color: ${props => props.theme.colors.caramel};
  border: 1px solid ${props => props.theme.colors.caramel};
  border-top-color: transparent;
  h3 {
    display: flex;
    color: ${props => props.theme.colors.caramel};
    min-height: 3.5rem;
    align-items: center;
    justify-content: center;
    margin: 0 0 1rem;
    text-align: center;
  }
  h4 {
    color: ${props => props.theme.colors.caramel};
  }
  p {
    margin: 0;
    padding: 0.25rem;
    border: 1px solid ${props => props.theme.colors.caramel};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Albums = ({ intl, data }) => {
  const { edges } = data['allContentfulAlbum']

  const code = intl.locale.charAt(0).toUpperCase() + intl.locale.charAt(1)

  const locale = `title${code}`

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'see_all_albums' })} />
      <Container>
        {edges &&
          edges.map((edge, index) => {
            const album = `/${edge.node.name}/`
            const title = edge.node[locale]

            return (
              <Album key={index}>
                <Image image={edge.node['frontpage']['fluid']} alt={'Album front page'} />
                <Link to={album}>
                  <Title>
                    <h3>{title}</h3>
                    <h4>{format(new Date(edge.node.date), 'DD/MM/YYYY')}</h4>
                    <p>{intl.formatMessage({ id: 'see_album' })}</p>
                  </Title>
                </Link>
              </Album>
            )
          })}
      </Container>
    </Layout>
  )
}

export const albumsPageQuery = graphql`
  query {
    allContentfulAlbum(
      sort: { fields: [date], order: DESC }
      filter: { name: { ne: "stock" } }
    ) {
      edges {
        node {
          name
          titleEn
          titleRu
          titleNl
          frontpage {
            fluid: gatsbyImageData(layout: FULL_WIDTH)
          }
          date
        }
      }
    }
  }
`

export default injectIntl(Albums)
